import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="app__footer-out">
      <div className="app__footer-wrapper">

        <p>Bellini <br /> Frantzplatz <br /> 67487 Maikammer</p>

        <p className="middle">Öffnungszeiten <br /> Montag - Sonntag <br />9:30 - 22:00</p>

        <div className="app__footer-logoContainer">
          <img className="app__footer-logo" src={require("../../assets/Logoo.png")} alt="Logo" />
        </div>

        <p className="middle text verticalCenter">
          Bleiben <br /> Sie <br /> gesund !
        </p>

        <p className="verticalCenter">
          <div className="right">
            <Link to={"/impressum"}> Impressum </Link>
            <Link to={"/datenschutz"}> Datenschutz </Link>
            <a href="mailto:info@cafebellini.de">info@cafebellini.de</a>
          </div>
        </p>

      </div>
    </div>
  );
};

export default Footer;
